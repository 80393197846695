import range from 'lodash.range';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTz } from '~/components/hooks/useTz';
import IconLeft from '~/components/icons/streamline/line/IconLeft';
import IconRight from '~/components/icons/streamline/line/IconRight';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export interface DatePickerProps {
  value: TDateISODate;
  children?: ReactNode;
  filter?: (date: DateTime) => boolean;
  onChange: (date: TDateISODate) => void;
}

export default function DatePicker(props: DatePickerProps) {
  const tz = useTz();

  return (
    <Calendar
      date={tz(props.value)}
      onChange={(value) => {
        props.onChange(value.toSQLDate());
      }}
      filter={props.filter}
    />
  );
}

type SelectionState = 'date' | 'month' | 'year';

interface CalendarProps {
  date: DateTime;
  onChange: (date: DateTime) => void;
  filter?: (date: DateTime) => boolean;
}

function Calendar(props: CalendarProps) {
  const [selState, setSelState] = useState<SelectionState>('date');
  const [dateClone, setDateClone] = useState(props.date);

  useEffect(() => {
    setDateClone(props.date);
  }, [props.date]);

  return (
    <div
      className="bg-white relative max-w-md w-64"
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      {selState === 'month' && (
        <MonthSelection
          date={props.date}
          innerDate={dateClone}
          onChangeInnerDate={setDateClone}
          onChangeSelectionState={setSelState}
        />
      )}
      {selState === 'date' && (
        <DateSelection
          date={props.date}
          innerDate={dateClone}
          filter={props.filter}
          onChange={(date) => {
            setDateClone(date);
            props.onChange(date);
          }}
          onChangeInnerDate={setDateClone}
          onChangeSelectionState={setSelState}
        />
      )}
      {selState === 'year' && (
        <YearSelection
          date={props.date}
          innerDate={dateClone}
          onChangeInnerDate={setDateClone}
          onChangeSelectionState={setSelState}
        />
      )}
    </div>
  );
}

interface SelectionProps {
  date: DateTime;
  innerDate: DateTime;
  onChangeInnerDate: (date: DateTime) => void;
}

interface DateSelectionProps extends SelectionProps {
  onChange: (date: DateTime) => void;
  filter?: (date: DateTime) => boolean;
  onChangeSelectionState: (state: SelectionState) => void;
}

interface MonthYearSelectionProps extends SelectionProps {
  onChangeSelectionState: (state: SelectionState) => void;
}

interface CalendarCellProps {
  date: DateTime;
  state: DateTime;
  isDisabled: boolean;
  onClick: (date: DateTime) => void;
}

function CalendarCell({ isDisabled, state, onClick, date }: CalendarCellProps) {
  const classes = ['rounded-full m-0.5 w-8 h-8 p-1 text-sm m-auto'];

  if (isDisabled) {
    classes.push('bg-red-50 cursor-not-allowed');
  } else {
    if (state.month !== date.month) {
      classes.push('text-gray-400');
    } else if (state.day === date.day) {
      classes.push('bg-gray-900 text-white font-semibold');
    } else {
      classes.push('hover:bg-gray-200 text-gray-900');
    }
  }

  return (
    <button
      type="button"
      className={`${classes.join(' ')} text-center`}
      onClick={(e) => {
        e.preventDefault();

        if (!isDisabled && onClick) {
          onClick(date);
        }
      }}
    >
      {date.day}
    </button>
  );
}

function DateSelection(props: DateSelectionProps) {
  const days = useMemo(() => {
    const startOfMonth = props.innerDate.startOf('month');
    const dates = [];

    let day = startOfMonth.weekday === 7 ? startOfMonth : startOfMonth.minus({ days: startOfMonth.weekday });

    while (dates.length < 42) {
      dates.push(day);

      day = day.plus({ day: 1 });
    }

    return dates;
  }, [props.innerDate]);

  return (
    <div className="text-gray-800 w-full">
      <div className="flex pb-2 mr-2">
        <button
          type="button"
          className={`${buttonClassName} flex font-semibold flex-grow items-left justify-start`}
          style={{ gridColumn: '2/5' }}
          onClick={(e) => {
            e.preventDefault();

            props.onChangeSelectionState('month');
          }}
        >
          {props.innerDate.toFormat('MMMM')} {props.innerDate.year}
        </button>
        <button
          type="button"
          className={`${buttonClassName} mr-2 flex items-center justify-center text-center`}
          onClick={(e) => {
            e.preventDefault();

            props.onChangeInnerDate(props.innerDate.minus({ month: 1 }));
          }}
        >
          <div className="font-bold text-xl flex mt-1 size-4">
            <IconLeft />
          </div>
        </button>
        <button
          type="button"
          className={`${buttonClassName} mr-2 flex items-center justify-center text-center`}
          onClick={(e) => {
            e.preventDefault();

            props.onChangeInnerDate(props.innerDate.plus({ month: 1 }));
          }}
        >
          <div className="font-bold text-xl mt-1 pr size-4">
            <IconRight />
          </div>
        </button>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
          gridTemplateRows: '2rem auto',
          alignItems: 'stretch',
        }}
      >
        {daysOfWeek.map((day) => (
          <div className="p-1 text-regular text-gray-700 m-auto text-center" key={day}>
            {day[0]}
          </div>
        ))}
        {days.map((day) => (
          <CalendarCell
            date={day}
            key={day.toISO()}
            state={props.innerDate}
            isDisabled={!!props.filter && !props.filter(day)}
            onClick={props.onChange}
          />
        ))}
      </div>
    </div>
  );
}

function MonthSelection(props: MonthYearSelectionProps) {
  function dateWithMonth(date: DateTime, month: number): DateTime {
    return date.set({ month });
  }

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <CalButton chevron="left" onClick={() => props.onChangeInnerDate(props.innerDate.minus({ year: 1 }))} />
        <CalButton className="flex-grow" onClick={() => props.onChangeSelectionState('year')}>
          {props.innerDate.year}
        </CalButton>
        <CalButton chevron="right" onClick={() => props.onChangeInnerDate(props.innerDate.plus({ year: 1 }))} />
      </div>
      {months.map((month, index) => (
        <CalButton
          onClick={() => {
            props.onChangeInnerDate(dateWithMonth(props.innerDate, index + 1));
            props.onChangeSelectionState('date');
          }}
        >
          {month.substring(0, 3)}
        </CalButton>
      ))}
    </div>
  );
}

function YearSelection(props: MonthYearSelectionProps) {
  const minYear = () => props.innerDate.year - 6;
  const maxYear = () => minYear() + 12;

  return (
    <div
      className="h-48"
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '2rem auto',
        alignItems: 'stretch',
      }}
    >
      <div className="flex" style={{ gridColumn: '1/5' }}>
        <CalButton chevron="left" onClick={() => props.onChangeInnerDate(props.innerDate.minus({ year: 1 }))} />
        <CalButton className="flex-grow">{`${minYear()} - ${maxYear() - 1}`}</CalButton>
        <CalButton chevron="right" onClick={() => props.onChangeInnerDate(props.innerDate.plus({ year: 1 }))} />
      </div>
      {range(minYear(), maxYear()).map((year) => (
        <CalButton
          onClick={() => {
            props.onChangeInnerDate(props.innerDate.set({ year }));
            props.onChangeSelectionState('month');
          }}
        >
          {year}
        </CalButton>
      ))}
    </div>
  );
}

const buttonClassName = 'hover:bg-gray-200 rounded p-1 text-sm flex align-center pl-2 focus:outline-none';

function CalButton(props: {
  chevron?: 'right' | 'left';
  className?: string;
  children?: React.ReactNode;
  style?: Record<string, string>;
  onClick?: () => void;
}) {
  let children = null;

  if (props.chevron && props.chevron === 'left') {
    children = <IconLeft />;
  } else if (props.chevron && props.chevron === 'right') {
    children = <IconRight />;
  } else {
    children = props.children;
  }

  return (
    <button
      type="button"
      className={`${buttonClassName} ${props.className}`}
      style={props.style}
      onClick={(e) => {
        e.preventDefault();

        props.onClick?.();
      }}
    >
      {children}
    </button>
  );
}
